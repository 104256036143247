// extracted by mini-css-extract-plugin
export var mainContainer = "blog-module--mainContainer--Hoge2";
export var mail = "blog-module--mail--b0jeg";
export var socialLogo = "blog-module--socialLogo--eQdJi";
export var socialLogoX = "blog-module--socialLogoX--ncNY8";
export var facebookLogo = "blog-module--facebookLogo--gxp8W";
export var error = "blog-module--error--5wyrk";
export var h1 = "blog-module--h1--2i-+N";
export var twitterShareButton = "blog-module--twitterShareButton--RHRhN";
export var linkedinShareButton = "blog-module--linkedinShareButton--vFz81";
export var socialShareContainer = "blog-module--socialShareContainer--IG1Ca";
export var boldAuthor = "blog-module--boldAuthor--XhC6P";
export var adContainerWide = "blog-module--adContainerWide--5Jw6o";
export var adContainerMedium = "blog-module--adContainerMedium--NVpUv";
export var adContainerSmall = "blog-module--adContainerSmall--enrK5";