// extracted by mini-css-extract-plugin
export var adInfo = "amazonAd-module--adInfo--P3F+J";
export var image = "amazonAd-module--image--f0bxp";
export var marginLeft1 = "amazonAd-module--marginLeft1--qeu+q";
export var description = "amazonAd-module--description--gfDpd";
export var adContainer = "amazonAd-module--adContainer--8Y6OP";
export var cursive = "amazonAd-module--cursive--DrLKm";
export var title = "amazonAd-module--title--DeB2c";
export var amazonBtn = "amazonAd-module--amazonBtn--mV9fc";
export var starContainer = "amazonAd-module--starContainer--9IjJo";
export var starIconsContainer = "amazonAd-module--starIconsContainer--Cvi3T";
export var adIndicator = "amazonAd-module--adIndicator--LNBW7";