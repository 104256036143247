import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import TwitterLogo from "../assets/twitter.svg";
import XLogo from "../assets/x-logo.svg";
import LinkedInLogo from "../assets/linkedin.svg";
import FacebookLogo from "../assets/fb.svg";
import * as styles from "../styles/blog.module.css";
import AmazonAd from "../components/amazonAd";

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        author
        date
        description
        imageurl
        isodate
      }
      fields {
        slug
      }
      html
    }
  }
`;

const Blog = props => {
  const shareOnFb = () => {
    global.open(
      "https://www.facebook.com/sharer/sharer.php?u=" +
        encodeURIComponent(
          "http://www.webdevolution.com/blog/" +
            props.data.markdownRemark.fields.slug,
          "facebook-share-dialog",
          "width=626,height=436"
        )
    );
  };
  return (
    <Layout>
      <Helmet>
        <title>{props.data.markdownRemark.frontmatter.title}</title>
        <meta http-equiv="content-type" content="text/html;UTF-8" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta http-equiv="content-language" content="en-us"></meta>
        <meta
          name="twitter:image"
          content={props.data.markdownRemark.frontmatter.imageurl}
        />
        <meta
          name="twitter:title"
          content={props.data.markdownRemark.frontmatter.title}
        />
        <meta
          name="twitter:description"
          content={props.data.markdownRemark.frontmatter.description}
        />
        <meta
          name="description"
          content={props.data.markdownRemark.frontmatter.description}
        ></meta>
        <meta
          property="og:title"
          content={props.data.markdownRemark.frontmatter.title}
        />

        <meta
          property="og:url"
          content={`http://webdevolution.com/blog/${props.data.markdownRemark.fields.slug}`}
        />
        <meta
          property="og:image"
          content={props.data.markdownRemark.frontmatter.imageurl}
        />
        <meta
          property="og:description"
          content={props.data.markdownRemark.frontmatter.description}
        />
      </Helmet>

      <article className={styles.mainContainer}>
        <h1 className={styles.h1}>
          {props.data.markdownRemark.frontmatter.title}
        </h1>
        <p>
          <time datetime={props.data.markdownRemark.frontmatter.isodate}>
            {props.data.markdownRemark.frontmatter.date}
          </time>{" "}
          By{" "}
          <span className={styles.boldAuthor}>
            {props.data.markdownRemark.frontmatter.author}
          </span>
        </p>
        <div
          dangerouslySetInnerHTML={{ __html: props.data.markdownRemark.html }}
        ></div>
        <h3>SHARE</h3>
        <div className={styles.socialShareContainer}>
          <a className={styles.facebookLogo} onClick={shareOnFb}>
            {" "}
            <img
              className={styles.socialLogo}
              src={FacebookLogo}
              alt="Share on Facebook"
            />
          </a>
          <a
            className={styles.twitterShareButton}
            href={`https://twitter.com/intent/tweet?text=https://www.webdevolution.com/blog/${props.data.markdownRemark.fields.slug}`}
          >
            <img
              className={styles.socialLogoX}
              src={XLogo}
              alt="Tweet on Twitter"
            />
          </a>
          <a
            className={styles.linkedinShareButton}
            href={`https://www.linkedin.com/sharing/share-offsite/?url=http://www.webdevolution.com/blog/${props.data.markdownRemark.fields.slug}`}
          >
            <img
              className={styles.socialLogo}
              src={LinkedInLogo}
              alt="Share on LinkedIn"
            />
          </a>
          <a
            href={
              "mailto:?subject=" +
              props.data.markdownRemark.frontmatter.title +
              "&body=" +
              "http://www.webdevolution.com/blog/" +
              props.data.markdownRemark.fields.slug
            }
            className={styles.mail}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-mail"
            >
              <rect width="20" height="16" x="2" y="4" rx="2" />
              <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7" />
            </svg>
          </a>
        </div>
      </article>
      <div className={styles.adContainerWide}>
        <AmazonAd />
      </div>
      <div className={styles.adContainerSmall}>
        <a
          href="https://www.tkqlhce.com/click-100376026-15641618"
          target="_top"
        >
          <img
            src="https://i.ibb.co/pPY9gf2/affiliate-new-years-campaign-winter-couple-1200x1200.png"
            width="320"
            alt=""
            border="0"
          />
        </a>
      </div>
      <div className={styles.adContainerMedium}>
        <a
          href="https://www.jdoqocy.com/click-100376026-15641620"
          target="_top"
        >
          <img
            src="https://i.ibb.co/TM1WKH5/affiliate-new-years-campaign-winter-couple-728x90.png"
            width="729"
            height="90"
            alt=""
            border="0"
          />
        </a>
      </div>
    </Layout>
  );
};
export default Blog;
