import React, { useEffect, useState } from "react";
import AmazonLogo from "../assets/amazon-logo-white-sm.png";
import * as styles from "../styles/amazonAd.module.css";
import amazonLinks from "../data/amazonLinks";
import plausible from "gatsby-plugin-plausible";
// Get random number between 1 and 5

export default function AmazonAd() {
  const [random, setRandom] = useState(0);
  useEffect(() => {
    const randomNo = Math.floor(Math.random() * 7);
    setRandom(randomNo);
    window.plausible =
      window.plausible ||
      function () {
        (window.plausible.q = window.plausible.q || []).push(arguments);
      };
  }, []);

  const clickAd = () => {
    window.plausible("Amazon Ad Click", {
      callback: () => {
        return;
      },
    });
  };

  return (
    <a
      className={styles.adContainer}
      href={amazonLinks[random].link}
      target="_blank"
      onClick={() => clickAd()}
    >
      <img
        src={amazonLinks[random].image}
        alt={amazonLinks[random].title + " book cover"}
        className={styles.image}
      ></img>
      <div className={styles.adInfo}>
        <div>
          <h2 className={styles.title}>
            {amazonLinks[random].title}
            <span className={styles.adIndicator}>Ad</span>{" "}
          </h2>
          <span className={styles.starContainer}>
            {amazonLinks[random].score}
            <span className={styles.starIconsContainer}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="#ffa41c"
                stroke="#ffa41c"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-star"
              >
                <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="#ffa41c"
                stroke="#ffa41c"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-star"
              >
                <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="#ffa41c"
                stroke="#ffa41c"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-star"
              >
                <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="#ffa41c"
                stroke="#ffa41c"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-star"
              >
                <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="#ffa41c"
                stroke="#ffa41c"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-star"
              >
                <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
              </svg>
            </span>
          </span>
          <p className={styles.description}>{amazonLinks[random].subtitle}</p>
          <p className={`${styles.description} ${styles.cursive}`}>
            By{" "}
            {amazonLinks[random].author.map(a => {
              if (
                a ==
                amazonLinks[random].author[
                  amazonLinks[random].author.length - 1
                ]
              ) {
                return a + " ";
              } else {
                return a + ", ";
              }
            })}{" "}
          </p>
        </div>
        <button className={styles.amazonBtn}>
          Buy from Amazon
          <img class={styles.marginLeft1} src={AmazonLogo} alt="Amazon logo" />
        </button>
      </div>
    </a>
  );
}
